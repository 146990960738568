<style lang="scss" scoped>
</style>

<template>
  <div>
      <product-list :products="animalEcgProducts"></product-list>
  </div>
</template>

<script>
import ProductList from './components/productList.vue'
import { mapState } from 'vuex'
import {getProducts}  from "@/api/apis";

export default {
  name: 'AnimalEcg',
  components: { ProductList },
  data() {
    return {
      animalEcgProducts: [],
      statusCode:0,
      animalLists:[],
      timer:true
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
      products: (state) => state.products.list,
      productValid: (state) => state.products.valid
    })
  },
  methods: {
    getECData(){
      getProducts(3).then(result=>{
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.animalLists=result.data.rows;
        this.animalEcgProducts.pop();
        for (let i=0;i<this.animalLists.length;i++){
          if (this.animalLists[i].itype===this.statusCode&&this.animalLists[i].ptype===3){
            this.animalEcgProducts.push(this.animalLists[i]);
          }
        }
      })
    },
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      //等待数据链接，保证数据加载完毕
      await this.sleep(200)
      this.timer=false;
    }
  },
  mounted() {
    this.getECData()
    this.test()
  }
}
</script>
