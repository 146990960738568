<style lang="scss" scoped>
</style>

<template>
  <div>
    <product-list :products="aecgProducts"></product-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductList from './components/productList.vue'
import {getProducts}  from "@/api/apis";
export default {
  components: { ProductList },
  name: 'Aecg',
  data() {
    return {
      aecgProducts: [],
      aecgProductsList:[],
      statusCode:0,
      timer:true,
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
    })
  },
  methods: {
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    },
    getAECData(){
      getProducts(1).then((result)=>{
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.aecgProductsList=result.data.rows;
        this.aecgProducts.pop();
        for (let i=0;i<this.aecgProductsList.length;i++){
          if (this.aecgProductsList[i].itype===this.statusCode&&this.aecgProductsList[i].ptype===1){
            this.aecgProducts.push(this.aecgProductsList[i]);
          }
        }
      })
    }
  },
  mounted() {
    this.getAECData()
    this.test()
  }
}
</script>
