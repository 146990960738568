
const homeMaterials = {
  homePopularProduct:'Hot Products',
  homeAbout:'About Us',
  homeAboutImg: require('/public/image/home/about.jpg'),
  homeAboutTitle:'Design safe products and provide quality services',
  homeAboutDetail:`As a professional ECG solution provider, 3Ray Company adheres to the business principle of [technology, quality, service], With the cooperation concept of integrity, service and innovation, we sincerely invite distributors and agents from all over the country to create a bright future of mutual benefit and development.`,
  homeNews:'Latest News',
  homeMore:'More+',
  photo1:require('/public/image/home/轮播图/01.jpg'),
  photo2:require('/public/image/home/轮播图/02.jpg'),
  photo3:require('/public/image/home/轮播图/03.jpg'),
}


module.exports = {
  homeMaterials
}
