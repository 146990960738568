<style lang="scss" scoped>
.app-wrapper {
  flex-grow: 0;
  flex-shrink: 0;
}
.footer{
  flex-shrink: 0;
  flex-grow: 0;
}
.font{
  width: 100%;
  padding: 30px 0 35px;
  background: #ededed;
  margin-top: 30px;
}
.common{
  padding: 0 15px;
}
.title1{
  width: 100%;
  text-align: center;
  line-height: 28px;
  font-size: 20px;
  color: #363636;
  font-family: 'HelveLt',serif;
}
.development{
  width: 100%;
  margin-top: 18px;
  text-align: center;
  font-size: 0;
}
.development a {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 140px;
  height: 40px;
  margin: 0 10px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: #363636;
  background: #ffffff;
}
.development a.active {
  //color: #ffffff;
  //background: #1e89bb;
}

.development a#productionLineLink.active {
  color: #ffffff;
  //background: #1e89bb;
}
</style>
<template>
  <div>
    <div id="app">
      <div class="font">
        <div class="common">
          <h2 class="title1">{{title}}</h2>
          <div class="development">
            <a :class="{ active: activeLink === 'company' }" @click="setActiveLink('company')" style="pointer-events: none; cursor: default;">{{title0}}</a>
            <a :class="{ active: activeLink === 'productionLine' }" @click="setActiveLink('productionLine')" style="pointer-events: none; cursor: default;">{{title1}}</a>
          </div>
        </div>
      </div>
    </div>
    <!--    :data="develop"-->
    <timeline  :companyData="companyData" :productionData="productionData" class="mt-2"></timeline>
  </div>
</template>

<script>
import timeline from '../../components/timeline.vue'
import {getDevelop} from "@/api/apis";

export default {
  components: { timeline },
  name: 'Development',
  data() {
    return {
      // bgimg: require('public/image/about/developbg.jpg'),
      develop:[],
      devlist:[],
      companyData:[],
      productionData:[],
      statusCode:0,
      timer:true,
      title:'',
      activeLink: 'company',
      title0:'',
      title1:'',
    }
  },
  methods:{
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    setActiveLink(link) {
      this.activeLink = link;
      this.getECData();
      this.getECData0();
      console.log(this.productionData,this.companyData)
      // if (link === 'company'){
      //   this.companyData = [];
      //   this.develop=[]
      //   this.getECData()
      // }else if(link === 'productionLine'){
      //   this.productionData = [];
      //   this.develop=[]
      //   this.getECData0()
      // }

    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    },
    getLanguage(){
      if (this.$store.state.app.locale==="CHN"){
        this.title="发展历程",
          this.title0="公司发展",
          this.title1="产线发展"
        this.statusCode=0;
      }else if (this.$store.state.app.locale==="ENG"){
        this.title="Development",
          this.title0="Company",
          this.title1="Production",
          this.statusCode=1;
      }
    },
    getECData(){
      if (this.$store.state.app.locale==="CHN"){
        getDevelop(0,0).then(result=>{
          // this.develop=result.data.rows;
          this.companyData=result.data.rows;
        })
      }else if (this.$store.state.app.locale==="ENG"){
        getDevelop(0,1).then(result=>{
          // this.develop=result.data.rows;
          this.companyData=result.data.rows;
        })
      }

    },
    getECData0() {
      if (this.$store.state.app.locale==="CHN"){
        getDevelop(1,0).then(result=>{
          // this.develop=result.data.rows;
          this.productionData=result.data.rows;
        })
      }else if (this.$store.state.app.locale==="ENG"){
        getDevelop(1,1).then(result=>{
          // this.develop=result.data.rows;
          this.productionData=result.data.rows;
        })
      }
    },

  },
  mounted() {
    this.getLanguage()
    this.getECData()
    this.getECData0()
    // this.test()
  }
}
</script>
