const jobMaterials = {
  jobRD:'研发中心',
  jobMarket:'市场部',
  jobSale:'销售部',
  jobListTitlePosition:'职位名称',
  jobListTitleNumbers:'招聘人数',
  jobListTitleWorkplace:'工作地点',
  jobListTitleReleaseDate:'发布时间',
  jobDuty:'岗位职责',
  jobRequires:'任职资格',
  jobPostResume:'投递简历',
  jobReceiveMail:'info@3ray.com.cn',
  jobNoJobs:'暂未发布招聘信息',
}
module.exports = {
  jobMaterials
}
