<style lang="scss" scoped>
@import '@/styles/variables.scss';
.b-img {
  height:150px;
}
.about{
  max-width: 100%;
  overflow: hidden;
}
</style>

<template>
  <div class="about">
    <b-img fluid-grow src="/image/banner/关于.jpg"></b-img>
    <nav-item
      :itemList="navItems"
      :defaultActivePath="currentNavPath"
      @handleNavChange="handleNavClick"
    ></nav-item>
    <div class="container-fulid">
      <div class="row justify-content-center no-gutters">
        <div class="col-12 col-lg-9">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navItem from '@/components/navItem.vue'
export default {
  components: { navItem },
  name: 'About',
  data() {
    return {
      currentNavPath: '/about/introduction',
      timer:true
    }
  },
  computed: {
    navItems() {
      return this.$t('aboutChildren')
    }
  },
  methods: {
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    },
    handleNavClick(path) {
      this.currentNavPath = path
      this.$router.push(path)
    }
  },
  mounted() {
    this.test()
    this.currentNavPath = this.$route.path
  }
}
</script>
