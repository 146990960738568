const serviceMaterials = {
  contactUs: 'Contact us',
  localSale: '',
  forignSale: 'International sales',
  serviceTitle:'After-sales service',
  followUs:'Follow us',
  company:'Guangzhou 3Ray Electronic Technology Co., Ltd.',
  web:'Website record number:',
  webkit:'Guangdong ICP No. 09163161-2',
  butt:'Close',
  title1:'Certifications',
  title2:'Software Copyright',
  title3:'Trademark'
}

const serviceInfo = {
  contactTel: 'Tel: 020-4006801773',
  contactAddr:'Addr：4th FL,BLDG C3, Longshan IZ, No.260 Yinglong RD, Longdong, Tianhe DIST, Guangzhou',
  contactClientTel:'Customer Service：020-4006801773',
  contactSaleTel:"Sales call：020-37205766",
  companyBaseAddr: 'Addr：4th LF, BLDG C3, Longshan IZ, No.260 Yinglong RD, Longdong, Tianhe DIST, Guangzhou',
  companyFactoryAddr: 'Factory Addr：4th LF, BLDG C3, Longshan IZ, No.260 Yinglong RD, Longdong, Tianhe DIST, Guangzhou',
  // companyPartAddr1: '分公司：广州市越秀区农林下路8号新裕大厦3511/3512/3513室',
  fax: 'Fax：020-87203437',
  localSaleTel: '',
  localSaleFax: 'Fax：020-87203437',
  localSaleMail: 'E-mail：export@3ray.com.cn',
  forignSaleTel: 'Tel：020-87211973',
  forignSaleFax: 'Fax：020-87203437',
  forignSaleMail: 'E-mail：export@3ray.com.cn',
}
const dia={btn:'Close'}

module.exports = {
  serviceMaterials,
  serviceInfo,
  dia
}
