// const { companyProfile, developmentTimeLine } = require('./modulesEN/about')

const { jobs, jobMaterials } = require('./modulesEN/job')

const {
  serviceMaterials,
  serviceInfo,
  localServiceContactList
} = require('./modulesEN/service')

const { homeMaterials } = require('./modulesEN/home')


const routerTitle = {
  base:'3RAY Technology',
  home:'',
  about: {
    introduction: 'About us',
    certificate: 'Honors',
    development: 'Development',
    news: 'News'
  },
  product: {
    ecg: 'Ecg',
    aecg: 'Aecg',
    ultrasonics:'Ultrasonics',
    animalEcg: 'Animal ecg',
    ecgnet: 'EcgNet'
  },
  service: {
    site: 'ServiceSite',
    contact: 'Contact us'
  },
  join: 'Join'

}
const products1=[
  {
    id: 1,
    name: 'ECG Analysis',
    image: require('/public/image/home/心电图.png'),
    url:'/products/ecg'
  },
  {
    id: 2,
    name: 'Patient Monitoring',
    image: require('/public/image/home/监测点-未选.png'),
    url:'/products/aecg'
  },
  {
    id: 3,
    name: 'Ultrasound Imaging',
    image: require('/public/image/home/超声波.png'),
    url:'/products/ultrasonics'
  },
  {
    id: 4,
    name: 'Healthcare System',
    image: require('/public/image/home/科技.png'),
    url:'/products/ecgnet'
  },
  {
    id: 5,
    name: 'Pet Medical',
    image: require('/public/image/home/宠物医疗.png'),
    url:'/products/animalecg'
  }
]
const aboutChildren = [
  {
    name: 'About us',
    path: '/about/introduction',
  },
  {
    name: 'Honors',
    path: '/about/certificate',
  },
  {
    name: 'Development',
    path: '/about/development',
  },
  {
    name: 'News',
    path: '/about/news',
  }
]
const productsChildren = [
  {
    name: 'ECG Analysis',
    path: '/products/ecg',
  },
  {
    name: 'Patient Monitoring',
    path: '/products/aecg',
  },
  {
    name: 'Ultrasonics',
    path: '/products/ultrasonics',
  },
  {
    name: 'Healthcare System',
    path: '/products/ecgnet',
  },
  {
    name: 'Pet ECG',
    path: '/products/animalecg',
  }
]

const serviceChildren = [
  { name: 'Service outlets', path: '/service' },
  { name: 'Contact us', path: '/service' }
]

const mainNavList = [
  {
    name: 'Home',
    path: '/home',
    children: []
  },
  {
    name: 'About us',
    path: '/about',
    children: aboutChildren
  },
  {
    name: 'Products',
    path: '/products',
    children: productsChildren
  },
  {
    name: 'Service',
    path: '/service',
    // children: serviceChildren
    children: []
  },
  {
    name: 'Join us',
    path: '/join',
    children: []
  }
]

const footerNavList = [
  {
    name: 'About us',
    path: '/about',
    children: aboutChildren
  },
  {
    name: 'Products',
    path: '/products',
    children: productsChildren
  },
  {
    name: 'Service',
    path: '/service',
    children: serviceChildren
  },
  {
    name: 'Join us',
    path: '/join',
    children: [
      {
        name: 'Social recruitment',
        path: '/join'
      }
    ]
  }
]

module.exports = {
  products1,
  mainNavList,
  footerNavList,
  aboutChildren,
  productsChildren,
  serviceChildren,
  routerTitle,
  jobs,
  homeMaterials,
  jobMaterials,
  serviceMaterials,
  serviceInfo,
  localServiceContactList
}
