import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/utils/i18n'

Vue.use(VueRouter)

const productsChildren = [
  {
    path: '/products/ecg',
    component: () => import('@/views/products/ecg'),
    name: 'Ecg',
    meta: { title: 'routerTitle.product.ecg' }
  },
  {
    path: '/products/aecg',
    component: () => import('@/views/products/aecg'),
    name: 'AEcg',
    meta: { title: 'routerTitle.product.aecg' }
  },
  {
    path: '/products/ultrasonics',
    component: () => import('@/views/products/ultrasonics'),
    name: 'Ultrasonics',
    meta: { title: 'routerTitle.product.ultrasonics' }
  },
  {
    path: '/products/animalEcg',
    component: () => import('@/views/products/animal-ecg'),
    name: 'AnimalEcg',
    meta: { title: 'routerTitle.product.animalEcg' }
  },
  {
    path: '/products/ecgnet',
    component: () => import('@/views/products/ecgnet'),
    name: 'Ecgnet',
    meta: { title: 'routerTitle.product.ecgnet' }
  }
]

const aboutChildren = [
  {
    path: '/about/introduction',
    component: () => import('@/views/about/introduction'),
    name: 'Introduction',
    meta: { title: 'routerTitle.about.introduction' }
  },
  {
    path: '/about/certificate',
    component: () => import('@/views/about/certificate'),
    name: 'Certificate',
    meta: { title: 'routerTitle.about.certificate' }
  },
  {
    path: '/about/development',
    component: () => import('@/views/about/development'),
    name: 'Development',
    meta: { title: 'routerTitle.about.development' }
  },
  {
    path: '/about/news',
    component: () => import('@/views/about/news'),
    name: 'News',
    meta: { title: 'routerTitle.about.news' }
  }
]

// const productList = [
  // {
  //   path: '/product/ecg5512b',
  //   component: () => import('@/views/products/list/ecg5512b'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg5512B' }
  // },
  // {
  //   path: '/product/ecg5512g',
  //   component: () => import('@/views/products/list/ecg5512g'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg5512G' }
  // },
  // {
  //   path: '/product/ecg5515g',
  //   component: () => import('@/views/products/list/ecg5515g'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg5515G' }
  // },
  // {
  //   path: '/product/ecg5518g',
  //   component: () => import('@/views/products/list/ecg5518g'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg5518G' }
  // },
  // {
  //   path: '/product/ecg5503',
  //   component: () => import('@/views/products/list/ecg5503'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg5503' }
  // },
  // {
  //   path: '/product/ecg5506',
  //   component: () => import('@/views/products/list/ecg5506'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg5506' }
  // },
  // {
  //   path: '/product/ecg2303',
  //   component: () => import('@/views/products/list/ecg2303'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg2303' }
  // },
  // {
  //   path: '/product/ecg3306',
  //   component: () => import('@/views/products/list/ecg3306'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg3306' }
  // },
  // {
  //   path: '/product/ecg3312',
  //   component: () => import('@/views/products/list/ecg3312'),
  //   meta: { title: 'routerTitle.product.ecgDevices.ecg3312' }
  // },
  // {
  //   path: '/product/localEcgNet',
  //   component: () => import('@/views/products/list/localEcgNet'),
  //   meta: { title: 'routerTitle.product.ecgnetDevices.localEcgNet' }
  // },
  // {
  //   path: '/product/regionalEcgNet',
  //   component: () => import('@/views/products/list/regionalEcgNet'),
  //   meta: { title: 'routerTitle.product.ecgnetDevices.regionalEcgNet' }
  // }
// ]
const serviceChildren=[
  {
    path:'/service/messageDetail',
    component: () => import('@/views/service/messageDetail'),
  }
]
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () => import('@/views/home/index'),
    name: 'Home'
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about/index'),
    redirect: '/about/introduction',
    children: aboutChildren
  },

  {
    path: '/products',
    component: () => import('@/views/products/index'),
    name: 'Products',
    redirect: '/products/ecg',
    children: productsChildren
  },
  {
    path: '/service',
    component: () => import('@/views/service/index'),
    name: 'Service',
    // redirect: '/service/site',
    children: serviceChildren
  },
  {
    path: '/service/message',
    component: () => import('@/views/service/messageDetail'),
    name: 'Message',
  },
  {
    path: '/join',
    component: () => import('@/views/join/index'),
    name: 'Join',
    children: [],
    meta: {
      title: 'routerTitle.join'
    }
  },
  // {
  //   path: '/product',
  //   component: () => import('@/views/products/list/index'),
  //   name: 'Product',
  //   // children: productList
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  let title = i18n.t(to.meta.title)
  if (to.meta.title && title) {
    document.title = title + '  ' + i18n.t('routerTitle.base')
  } else {
    document.title = i18n.t('routerTitle.base')
  }
  next()
})

export default router
