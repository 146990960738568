<style lang="scss" scoped>
@import '@/styles/variables.scss';
</style>

<template>
  <div>
    <news-card
      class="px-2 wow fadeInUp"
      v-for="(item, index) in news"
      :key="index"
      :newsItem="item"
    ></news-card>
  </div>
</template>

<script>
import newsCard from './components/newsCard.vue'
import { WOW } from 'wowjs'
import { mapState } from 'vuex'
import {getNews} from "@/api/apis";
// import axios from "axios";
export default {
  components: { newsCard },
  name: 'News',
  data() {
    return {
      news: [],
      newsList: [],
      timer:true
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
    })
  },
  methods:{
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    },
    getECData(){
      getNews().then(result=>{
        // this.jobsList=result.data.rows;
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.newsList=result.data.rows;
        this.news.pop();
        for (let i=0;i<this.newsList.length;i++){
          if (this.newsList[i].itype===this.statusCode){
            this.news.push(this.newsList[i]);
          }
        }
      })
    },
  },
  mounted() {
    new WOW().init()
    this.getECData()
    this.test()
  }
}
</script>

