<template>
  <div class="main-wrapper">
    <div class="product-list-wrapper">
      <product-list :products="ecgNetProducts"></product-list>
    </div>
  </div>
</template>

<script>
import ProductList from './components/productList.vue'
import { mapState } from 'vuex'
import {getProducts} from "@/api/apis";
// import QRCode from "qrcodejs2";
export default {
  name: 'Ecgnet',
  components: { ProductList },
  data() {
    return {
      ecgNetProducts: [],
      ecgList:[],
      statusCode:0,
      timer:true,
      qrcode: null,
      appurl:[]
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
    })
  },
  methods: {
    getECData(){
      getProducts(2).then(result=>{
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.ecgList=result.data.rows;
        this.ecgNetProducts.pop();
        for (let i=0;i<this.ecgList.length;i++){
          if (this.ecgList[i].itype===this.statusCode){
            this.ecgNetProducts.push(this.ecgList[i]);
          }
        }
      })
    },
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    }
  },
  mounted() {
    this.getECData()
    this.test()
  }
}
</script>
<style lang="scss" scoped>
@media (max-width:580px) {
  .qrcodestyle{
    display: inline-block;
    width: 132px;
    padding-top: 30px;
    right: 30vw;
    img {
      width: 132px;
      height: 132px;
      background-color: #fff;
      box-sizing: border-box;
    }

  }
  .qrcodestyle1{
    display: inline-block;
    width: 132px;
    padding-top: 30px;
    right: 30vw;
    img {
      width: 132px;
      height: 132px;
      background-color: #fff;
      box-sizing: border-box;
    }

  }
}
.main-wrapper {
  display: flex;
  justify-content: space-between;
}

.product-list-wrapper {
  flex-grow: 1;
}

.qrcode-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.qrcodestyle{
  display: inline-block;
  position: relative;
  bottom: 50px;
  float: left;
  text-align: center;
  //right: 50px;
  padding-top: 30px;
  img {
    width: 132px;
    height: 132px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}
.qrcodestyle1{
  display: inline-block;
  position: relative;
  bottom: 50px;
  float: right;
  text-align: center;
  //right: 50px;
  padding-top: 30px;
  img {
    width: 132px;
    height: 132px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}
</style>
