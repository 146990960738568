

const { jobs, jobMaterials } = require('./modulesZH/job')

const {
  serviceMaterials,
  serviceInfo
} = require('./modulesZH/service')

const { homeMaterials } = require('./modulesZH/home')
const products0=[
  {
    id: 1,
    name: '心电分析',
    image: require('/public/image/home/心电图.png'),
    url:'/products/ecg'
  },
  {
    id: 2,
    name: '病人监护',
    image: require('/public/image/home/监测点-未选.png'),
    url:'/products/aecg'
  },
  {
    id: 3,
    name: '超声影像',
    image: require('/public/image/home/超声波.png'),
    url:'/products/ultrasonics'
  },
  {
    id: 4,
    name: '智慧心网',
    image: require('/public/image/home/科技.png'),
    url:'/products/ecgnet'
  },
  {
    id: 5,
    name: '宠物心电',
    image: require('/public/image/home/宠物医疗.png'),
    url:'/products/animalecg'
  }
]
const routerTitle = {
  base: '广州市三锐电子科技有限公司',
  home: '',
  about: {
    introduction: '关于我们',
    certificate: '获得荣誉',
    development: '发展历程',
    news: '最新动态'
  },
  product: {
    ecg: '常规心电',
    aecg: '动态心电',
    ultrasonics:'超声影像',
    animalEcg: '动物心电',
    ecgnet: '智慧心网'
  },
  service: {
    site: '服务网络',
    contact: '联系我们'
  },
  join: '加入我们'
}
const aboutChildren = [
  {
    name: '三锐简介',
    path: '/about/introduction',
  },
  {
    name: '获得荣誉',
    path: '/about/certificate',
  },
  {
    name: '发展历程',
    path: '/about/development',
  },
  {
    name: '公司动态',
    path: '/about/news',
  }
]

const productsChildren = [
  {
    name: '心电分析',
    path: '/products/ecg',
  },
  {
    name: '病人监护',
    path: '/products/aecg',
  },
  {
    name: '超声影像',
    path: '/products/ultrasonics',
  },
  {
    name: '智慧心网',
    path: '/products/ecgnet',
  },
  {
    name: '宠物心电',
    path: '/products/animalecg',
  }
]

const serviceChildren = [
  { name: '服务网络', path: '/service' },
  { name: '联系我们', path: '/service' }
]

const mainNavList = [
  {
    name: '三锐科技',
    path: '/home',
    children: []
  },
  {
    name: '关于我们',
    path: '/about',
    children: aboutChildren
  },
  {
    name: '产品中心',
    path: '/products',
    children: productsChildren
  },
  {
    name: '联系我们',
    path: '/service',
    // children: serviceChildren
    children: []
  },
  {
    name: '加入我们',
    path: '/join',
    children: []
  }
]

const footerNavList = [
  {
    name: '关于我们',
    path: '/about',
    children: aboutChildren
  },
  {
    name: '产品中心',
    path: '/products',
    children: productsChildren
  },
  {
    name: '客户服务',
    path: '/service',
    children: serviceChildren
  },
  {
    name: '加入我们',
    path: '/join',
    children: [
      {
        name: '社会招聘',
        path: '/join'
      }
    ]
  }
]
module.exports = {
  products0,
  mainNavList,
  footerNavList,
  aboutChildren,
  productsChildren,
  serviceChildren,
  routerTitle,
  jobs,
  homeMaterials,
  jobMaterials,
  serviceMaterials,
  serviceInfo
}
