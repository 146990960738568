<style lang="scss" scoped>
@import '@/styles/variables.scss';
</style>
<template>
  <div>
    <b-img fluid-grow src="/image/banner/招聘.jpg"> </b-img>
    <!-- 招聘 -->
    <div class="container-fulid">
      <div class="row no-gutters d-flex justify-content-center">
        <div class="col-12 col-lg-9">
          <recruitment class="mt-2" :jobs="jobsList"></recruitment>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Recruitment from './components/recruitment.vue'
import { mapState } from 'vuex'
export default {
  components: { Recruitment },
  name: 'Join',
  data() {
    return {
      jobList: [],
      jobsList: [],
      timer:true
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
      jobs: (state) => state.job.jobs,
      jobValid: (state) => state.job.valid
    })
  },
  methods:{
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    }
  },
  mounted() {
   this.test()
  }
}
</script>

