<style lang="scss" scoped>
.main {
  background-color: cadetblue;
  height: 100px;
}
@media screen and (max-width:720px){
  *{
    margin:10px auto;
  }
}
.detail-wrapper {
  text-indent: 2rem;
  line-height: 3rem;
  float: left;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: 18px;
  color: #696969;
}
</style>
<template>
  <div >
    <div class="py-3"  v-for="(item,index) in introduction" :key="index + 'x'">
        <b-img-lazy
          class="col-md-3 col-lg-3 col-xs-12"
          style="float:left;width:50%"
          :src="api+item.imgpath"
        >
        </b-img-lazy>
        <p  v-for="(item1, index) in stringCut(item.description)"
            :key="index">
          {{ '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'+item1 }}
        </p>
    </div>
  </div>
</template>

<script>
import {imgUrl,getCprofile} from'@/api/apis'
export default {
  name: 'Introduction',
  data() {
    return{
      introduction:[],
      list1:[],
      api:imgUrl,
      timer:true
    }
  },
  methods:{
    stringCut(dec){
      let list=dec.split("；");
      return list;
    },
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    },
    getECData(){
      getCprofile().then(result=>{
        // this.jobsList=result.data.rows;
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.list1=result.data.rows;
        this.introduction.pop();
        for (let i=0;i<this.list1.length;i++){
          if (this.list1[i].itype===this.statusCode){
            this.introduction.push(this.list1[i]);
          }
        }
      })
    },
  },
  mounted() {
    this.getECData()
    this.test()
  }
}
</script>


