<style lang="scss" scoped>
@import '@/styles/variables.scss';
.home-wrapper{
  max-width: 100%;
  overflow: hidden;
}
//圆形切换按钮
::v-deep {
  .carousel-indicators li {
    background-color:$sysTheameColor;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border-top: none;
    border-bottom: none;
  }
}
img{
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.scroll-wrapper {
  overflow: hidden;
}
.product-container {
  text-align: center;
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.product-item {
  text-align: center;
}

.product-image {
  max-width: 100%;
  height: auto;
}

.product-name {
  margin-top: 10px;
}
</style>
<template>
  <div class="home-wrapper">
    <div class="main-carousel-wrapper" v-show="homeMaterials[0].length!==0">
      <b-carousel
        id="carousel-1"
        :interval="4000"
        controls
        indicators
        img-width="100vw"
        img-height="550"
        style="text-shadow: 1px 1px 2px #333;"
        oncontextmenu="return true"
      >
        <b-carousel-slide
          v-if="homeMaterials[0].photo1!==null&&homeMaterials[0].photo1!==''"
          :img-src="api+homeMaterials[0].photo1"
          @click.native="$router.push('/products/ecg')"
        ></b-carousel-slide>

        <b-carousel-slide
          v-if="homeMaterials[0].photo2!==null&&homeMaterials[0].photo2!==''"
          :img-src="api+homeMaterials[0].photo2"
          @click.native="$router.push('/products/ecgnet')"
        >
        </b-carousel-slide>
        <b-carousel-slide
          v-if="homeMaterials[0].photo3!==null&&homeMaterials[0].photo3!==''"
          :img-src="api+homeMaterials[0].photo3"
          @click.native="$router.push('/products/aecg')"
        >
        </b-carousel-slide>
      </b-carousel>
    </div>
    <div class="product-container">
      <h2 class="title">{{title}}</h2>
      <div class="image-grid">
        <div v-for="product in products" :key="product.id" class="product-item">
          <div @click="$router.push(product.url)" class="product-content">
            <img :src="product.image" alt="Product Image" class="product-image" />
            <p class="product-name">{{product.name}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {getHomeList,imgUrl} from '@/api/apis'
import {products1} from "@/lang/en";
import {products0} from "@/lang/zh";

export default {
  components: {
    // ScrollItem
  },
  data() {
    return {
      api:imgUrl,
      // 热销产品
      hotProducts: [{imgpath: '',itype:0,pname: '',ptype:0}],
      hotProductsList: [],
      news: [],
      statusCode:0,
      homeMaterials:[{homePopularProduct:null,homeAbout:null,homeAboutImg:null,homeAboutTitle:null,homeAboutDetail:null,homeNews:null,homeMore:null,photo1:null,photo2:null,photo3:null,itype:null}],
      homeLists:[],
      newsList:[],
      timer:true,
      title:"",
      products:[],
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
    }),
  },
  methods: {
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      if (this.$store.state.app.locale==="CHN"){
        this.products=products0;
        this.title="产品方案"
      }else if(this.$store.state.app.locale==="ENG"){
        this.products=products1;
        this.title="Product solutions"
      }
      console.log(this.products)
      this.timer = true;
      // await this.sleep(200)
      this.timer=false;
    },
    getECData(){
      getHomeList().then(result=>{
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.homeLists=result.data.rows;
        this.homeMaterials.pop();
        console.log(this.homeLists)
        for (let i=0;i<this.homeLists.length;i++){
          if (this.homeLists[i].itype===this.statusCode){
            this.homeMaterials.push(this.homeLists[i]);
          }
        }
      })
    },
  },
  created() {
    this.getECData()
    this.test()
  }
}
</script>
