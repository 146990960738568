<template>
  <div id="footer">
    <div class="container-fulid">
      <div class="row justify-content-center no-gutters">
        <!-- 三列布局 flex -->
        <div class="top-wrapper col-12 col-lg-9">
          <!-- 菜单 -->
          <div class="menu-list d-none d-md-flex">
            <div
              class="menu-list-item"
              v-for="(item, index) in $t('footerNavList')"
              :key="index"
            >
              <!-- <div {{ item.name }}</div> -->
              <b-link class="menu-item-title" :href="item.path">
                {{ item.name }}</b-link
              >
              <div
                class="menu-item-sub-item"
                v-for="(child, index) in item.children"
                :key="index"
              >
                <b-link :href="child.path">{{ child.name }}</b-link>
              </div>
            </div>
          </div>
          <!-- 联系信息 -->
          <div class="contact-list">
            <!-- 联系我们 -->
            <div class="contact-title">
              {{ $t('serviceMaterials.contactUs') }}
            </div>
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
              </div>
              <div class="contact-info-detail">
                {{ $t('serviceInfo.contactAddr') }}
              </div>
            </div>
            <!-- 联系电话 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <b-icon-telephone-fill></b-icon-telephone-fill>
              </div>
              <div class="contact-info-detail">
                {{ $t('serviceInfo.contactClientTel') }}
              </div>
            </div>
            <!-- 传真 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <b-icon-printer-fill></b-icon-printer-fill>
              </div>
              <div class="contact-info-detail">
                {{ $t('serviceInfo.localSaleFax') }}
              </div>
            </div>
            <!-- 销售服务热线 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <i class="zi zi_tty"></i>
              </div>
              <div class="contact-info-detail">
                {{ $t('serviceInfo.contactSaleTel') }}
              </div>
            </div>
            <!-- 邮箱 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <b-icon-envelope-fill></b-icon-envelope-fill>
              </div>
              <div class="contact-info-detail">
                {{ $t('serviceInfo.localSaleMail') }}
              </div>
            </div>
          </div>

          <!-- 二维码 -->
          <div class="contact-qrcode">
            <div>
              <b-img
                class="mx-auto"
                center
                rounded
                width="120px"
                :src="require('/public/image/公众号.jpg')"
                fluid
                alt="Responsive image"
              ></b-img>
              <div class="qrcode-title">
                {{ $t('serviceMaterials.followUs') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 电脑底部显示 -->
    <div class="bottom-wrapper">
      <div class="d-none d-sm-block">
        Copyright &copy; {{ $t('serviceMaterials.company') }}&nbsp;&nbsp;{{ $t('serviceMaterials.web') }} <a
          href="https://beian.miit.gov.cn/#/Integrated/recordQuery"
          >{{ $t('serviceMaterials.webkit') }}</a
        >
      </div>
      <!--    手机底部显示-->
      <div class="d-block d-sm-none">
        <div class="footer-content-mobile">
          <p>Copyright &copy; {{ $t('serviceMaterials.company') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      // url: 'https://beian.miit.gov.cn/#/Integrated/index'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

#footer {
  clear: both;
  color: #999;
  background-color: $sysFooterBGColor;
  text-align: center;
  font-size: 0.8rem;
  width: 100%;
  .footer-content-mobile {
    p {
      height: 25px;
      margin: 0 auto;
    }
  }
}

.top-wrapper {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.menu-list {
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  flex-shrink: 0;
  a {
    color: #999;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
}

.menu-list-item {
  margin: 1rem;
}

.menu-item-title {
  font-size: 0.9rem;
  &:hover {
    color: #fff;
  }
}

.menu-item-sub-item {
  font-size: 0.8rem;
  margin: 0.5rem;
  &:hover {
    color: #fff;
  }
}

.contact-list {
  margin: 1rem;
}

.contact-title {
  font-size: 0.9rem;
  text-align: left;
}

@media screen and (max-width: 768px){
.contact-title {
  font-size: 1rem;
  text-align: center;
}

}

.contact-info-item {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-start;
  margin: 0.5rem;
  .contact-info-icon {
    margin-right: 1rem;
  }

  .contact-info-detail {
    font-size: 0.8rem;
    text-align: left;
  }
}

.contact-qrcode {
  margin: 1rem;
  flex-shrink: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;

  .qrcode-title {
    font-size: 0.9rem;
  }
}
.bottom-wrapper {
  height: 50px;
  align-items: center;
  border-top: 1px solid #444;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
}
</style>
