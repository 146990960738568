<template>
  <div>
    <div class="job-title d-none">
      <div class="row bg-second justify-content-center no-gutters">
        <div class="col-sm-3">
          {{ $t('jobMaterials.jobListTitlePosition') }}
        </div>
        <div class="col-sm-2">
          {{ $t('jobMaterials.jobListTitleNumbers') }}
        </div>
        <div class="col-sm-4">
          {{ $t('jobMaterials.jobListTitleWorkplace') }}
        </div>
        <div class="col-sm-3 text-left">
          {{ $t('jobMaterials.jobListTitleReleaseDate') }}
        </div>
      </div>
    </div>

    <div>
      <div class="job-list my-5 px-2 px-lg-0" v-for="(item, index) in jobsList" :key="index">
        <div class="job-header">
          <div class="job-position">{{ item.position }}</div>
          <div class="mt-3">{{ item.startime }}</div>
        </div>

        <div class="detail-title mt-3">
          {{ $t('jobMaterials.jobDuty') }}
        </div>
        <ul>
          <li
            class="detail-desc"
            v-for="(item0, index) in stringCut(item.duty)"
            :key="index"
          >
            {{ item0}}
          </li>
        </ul>
        <div class="detail-title">
          {{ $t('jobMaterials.jobRequires') }}
        </div>
        <ul>
          <li
            class="detail-desc"
                      v-for="(item1, index) in stringCut(item.requires)"
                      :key="index"
          >
            {{ item1}}
          </li>
        </ul>
      </div>
    </div>
    <div class="contact-wrapper">
      <b-link :href="'mailto:' + $t('jobMaterials.jobReceiveMail')"
        ><b-icon-envelope class="mx-1"></b-icon-envelope
        >{{ $t('jobMaterials.jobPostResume') }}
        {{ $t('jobMaterials.jobReceiveMail') }}
      </b-link>
    </div>
  </div>
</template>
<script>
import {getJobList} from "@/api/apis";

export default {
  data() {
    return {
      firstList:[],
      jobsList:[],
      statusCode: 0,
      timer:true
    }
  },
  methods:{
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(3800)
      this.timer=false;
    },
    getECData(){
      getJobList().then(result=>{
        // this.jobsList=result.data.rows;
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.firstList=result.data.rows;
        this.jobsList.pop();
        for (let i=0;i<this.firstList.length;i++){
          if (this.firstList[i].itype===this.statusCode){
            this.jobsList.push(this.firstList[i]);
          }
        }
      })
    },
    stringCut(dec){
      if(dec){
        let list=dec.split("；");
        return list;
      }
    }
  },
  mounted() {
    this.getECData()
    this.test()
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.job-list {
  text-align: center;
  font-size: 1rem;
  // border: 1px solid rgb(190, 203, 218);
  a {
    text-decoration: none;
  }
  .job-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid $sysTheameColor;
    color: $sysTheameColor;
    font-size: 1rem;
    .job-position {
      font-size: 1.5rem;
    }
  }
  .job-list-more-icon {
    height: 2rem;
    line-height: 2rem;
    margin-right: 10px;
  }
  .not-collapsed {
    svg {
      animation: doCollapse 0.5s forwards;
      -webkit-animation: doCollapse 0.5s forwards;
    }
  }
  .collapsed {
    svg {
      animation: doNotCollapse 0.5s backwards;
      -webkit-animation: doNotCollapse 0.5s backwards;
    }
  }

  .detail-title {
    text-align: left;
    margin: 5px 10px;
    // border-bottom: 1px solid $sysTheameColor;
    font-size: 1rem;
    color: $sysTheameColor;
  }

  .detail-desc {
    list-style-type: decimal;
    text-align: left;
    color: #777;
    font-size: 0.9rem;
  }
}
.contact-wrapper {
  display: block;
  margin: 50px auto;
  text-align: center;
  font-size: 2rem;
  color: $sysTheameColor;
  a {
    text-decoration: none;
  }
}
.app-wrapper {
  //margin-top: $sysHeaderHeight;
  // min-height: calc(100% - #{$sysFooterHeight});
  flex-grow: 0;
  flex-shrink: 0;
}
.footer{
  flex-shrink: 0;
  flex-grow: 0;
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 1 */
  border-top-color: #FFF;
  -webkit-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -moz-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 2s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  /* COLOR 2 */
  border-top-color: #FFF;
  -webkit-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -moz-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 3s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #FFF;
  /* COLOR 3 */
  -moz-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -o-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -ms-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  -webkit-animation: spin 1.5s linear infinite;
  /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite;
  /* Chrome, Firefox 16+, IE 10+, Opera */
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg);
    /* IE 9 */
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg);
    /* IE 9 */
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #7171C6;
  /* Old browsers */
  z-index: 1000;
  -webkit-transform: translateX(0);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0);
  /* IE 9 */
  transform: translateX(0);
  /* Firefox 16+, IE 10+, Opera */
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}
/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%);
  /* IE 9 */
  transform: translateX(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%);
  /* IE 9 */
  transform: translateX(100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}
.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%);
  /* IE 9 */
  transform: translateY(-100%);
  /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
/* JavaScript Turned Off */
.no-js #loader-wrapper {
  display: none;
}
.no-js h1 {
  color: #222222;
}
#loader-wrapper .load_title {
  font-family: 'Open Sans';
  color: #FFF;
  font-size: 19px;
  width: 100%;
  text-align: center;
  z-index: 9999999999999;
  position: absolute;
  top: 60%;
  opacity: 1;
  line-height: 30px;
}
#loader-wrapper .load_title span {
  font-weight: normal;
  font-style: italic;
  font-size: 13px;
  color: #FFF;
  opacity: 0.5;
}
</style>
