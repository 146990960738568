const serviceMaterials = {
  contactUs: '联系我们',
  localSale: '国内销售部',
  forignSale: '国际销售部',
  serviceTitle:'售后服务中心',
  followUs:'官方公众号',
  company:'广州市三锐电子科技有限公司版权所有',
  web:'网站备案号：',
  webkit:'粤ICP备09163161号-2',
  butt:'关闭',
  title1:'企业资质',
  title2:'软件著作',
  title3:'商 标'
}

// const titles={
//   "title1":'营业'
// },
const serviceInfo = {
  contactTel: '客户服务热线: 400-680-1773',
  contactAddr:'地址：广州市天河区龙洞迎龙路260号龙山工业区C3栋四楼',
  contactClientTel:'电话：400-680-1773',
  contactSaleTel:"销售服务热线：020-37205766",
  companyBaseAddr: '公司地址：广州市天河区龙洞迎龙路260号龙山工业区C3栋四楼',
  companyFactoryAddr: '生产基地：广州市天河区龙洞迎龙路260号龙山工业区C3栋四楼',
  // companyPartAddr1: '分公司：广州市越秀区农林下路8号新裕大厦3511/3512/3513室',
  fax: '传真：020-87203437',
  localSaleTel: '电话：020-37205766',
  localSaleFax: '传真：020-87203437',
  localSaleMail: '邮箱：info@3ray.com.cn',
  forignSaleTel: '电话：020-87211973',
  forignSaleFax: '传真：020-87203437',
  forignSaleMail: '邮箱：export@3ray.com.cn',
  wx:'微信公众号'
}
module.exports = {
  serviceMaterials,
  serviceInfo
}
