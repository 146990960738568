<style>
/* 页面容器样式 */
.contact-container {
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  display: flex; /* 使用 Flexbox */
  flex-direction: column; /* 垂直方向排列子元素 */
  align-items: center; /* 水平居中子元素 */
}

/* 标题样式 */
h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* 标签和内容样式 */
label {
  font-weight: bold;
  color: #666;
}

p {
  margin: 0 0 15px;
  color: #333;
}

/* 将姓名和电话放在同一行 */
.contact-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
</style>

<template>
  <div class="contact-container">
    <div>
      <h1>留言信息</h1>
      <div class="contact-info">
        <label for="name">姓名：</label>
        <p>{{ name }}</p>
      </div>
      <div class="contact-info">
        <label for="phone">联系电话：</label>
        <p>{{ phone }}</p>
      </div>
      <div>
        <div class="contact-info">
        <label for="message">留言信息：</label>
        <p>{{ message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getMessage } from "@/api/apis";

export default {
  data() {
    return {
      name: "张三",
      phone: "123-456-7890",
      message: "你好，我对你的产品很感兴趣！"
    };
  },
  methods: {
    getMsg(id) {
      console.log(222);
      getMessage(id)
        .then(res => {
          console.log(111);
          console.log(res);
          const message = res.data.data;
          this.name = message.name;
          this.phone = message.phonenumber;
          this.message = message.message;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    const id = this.$route.query.id;
    this.getMsg(id);
  }
};
</script>

