<style lang="scss" scoped>
@import '@/styles/variables.scss';
.main-content {
  // display: -webkit-flex;
  // display: flex;
  flex-wrap: wrap;
  .card-content {
    // display: block;
    width: 200px;
    // height: 300px;
    max-width: 200px;
    // max-height: 300px;
    overflow: hidden;
    margin: 20px 10px;
  }
}

.card-title {
  font-size: 1.3rem;
  color: $sysTheameColor;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid rgb(203, 232, 241);
}
.card-wrapper {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin:10px auto;
}
@media screen and (max-width:720px) {
  .main-content {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    .card-content {
      width: 150px;
      overflow: hidden;
    }
  }
  .card-wrapper {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin:0 auto;
  }
}

@media screen and (max-width: 720px) {
  .main-content {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    .card-content {
      max-width: 150px;
      float: left;
      overflow: hidden;
    }
    .b-img-lazy {
      width: 50%;
    }
    .card-wrapper {
      margin: 0 auto;
    }
  }

}
</style>
<template>
  <div>
    <div class="main-content mt-2">
      <div v-if="Qualification.length !==0">
        <div class="card-title">{{ $t('serviceMaterials.title1') }}</div>
        <div class="card-wrapper">
          <div
            class="card-content"
            v-for="(item, index) in Qualification"
            :key="index"
          >
            <b-img-lazy fluid-grow center v-if="item.imgpath!==null&&item.imgpath!==''" :src="api+item.imgpath" thumbnail>
            </b-img-lazy>
          </div>
        </div>
      </div>

      <div v-if="SoftwareCopyright.length !== 0">
        <div class="card-title">{{ $t('serviceMaterials.title2') }}</div>
        <div class="card-wrapper">
          <div
            class="card-content"
            v-for="(item, index) in SoftwareCopyright"
            :key="index"
          >
            <b-img-lazy fluid-grow center v-if="item.imgpath!==null&&item.imgpath!==''" :src="api+item.imgpath" thumbnail>
            </b-img-lazy>
          </div>
        </div>
      </div>

      <div v-if="Trademark.length!==0">
        <div class="card-title">{{ $t('serviceMaterials.title3') }}</div>
        <div class="card-wrapper">
          <div
            class="card-content"
            v-for="(item, index) in Trademark"
            :key="index"
          >
            <b-img-lazy fluid-grow center v-if="item.imgpath!==null&&item.imgpath!==''" :src="api+item.imgpath" thumbnail>
            </b-img-lazy>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import {imgUrl,getHonor} from'@/api/apis'
export default {
  name: 'Certificate',
  data() {
    return {
        api:imgUrl,
        Qualification: [{title:null,imgpath:null,imgtype:null,itype:0}],
        Trademark: [{title:null,imgpath:null,imgtype:null,itype:0}],
        SoftwareCopyright: [{title:null,imgpath:null,imgtype:null,itype:0}],
        photoesList: [],
        statusCode:0,
        timer:true
    }
  },
  // computed: {
    // ...mapState({
    //   certificateObj: (state) => state.certificate.certificates,
    //   certificateValid: (state) => state.certificate.valid
    // })
  // },
  methods:{
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    },
    getECData(){
     getHonor().then(result=>{
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.photoesList=result.data.rows;
        this.Qualification.pop();
        this.Trademark.pop();
        this.SoftwareCopyright.pop();
        for (let i=0;i<this.photoesList.length;i++){
          if (this.photoesList[i].imgtype===0){
            this.Qualification.push(this.photoesList[i]);
          }else if (this.photoesList[i].imgtype===2){
            this.Trademark.push(this.photoesList[i]);
          }else if (this.photoesList[i].imgtype===1){
            this.SoftwareCopyright.push(this.photoesList[i]);
          }
        }
      })
      console.log(2586)
    },
  },
  mounted() {
    this.getECData()
    this.test()
  }
}
</script>

