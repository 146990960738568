<style lang="scss" scoped>
@import '@/styles/variables.scss';
.products {
  max-width: 100%;
  overflow: hidden;
  margin: 0 auto;
}
</style>

<template>
  <div class="products">
    <b-img fluid-grow src="/image/home/轮播图/02.jpg"></b-img>
    <nav-item
      :itemList="navItems"
      :defaultActivePath="currentNavPath"
      @handleNavChange="handleNavClick"
    ></nav-item>
    <div class="container-fulid">
      <div class="row justify-content-center no-gutters">
        <div class="col-lg-12 col-xl-9 px-2 px-xl-0">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navItem from '@/components/navItem.vue'
export default {
  name: 'Products',
  components: {
    navItem
  },
  data() {
    return {
      active:'',
      currentNavPath: '/products/ecg',
      timer:true
    }
  },
  computed: {
    navItems() {
      return this.$t('productsChildren')
    }
  },
  methods: {
    handleNavClick(path) {
      this.currentNavPath = path
      this.$router.push(path)
    },
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      await this.sleep(200)
      this.timer=false;
    }
  },
  mounted() {
    this.currentNavPath = this.$route.path
    this.test()
  }
}
</script>

