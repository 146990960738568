// import request from '@/utils/request'
import axios from "axios";
export const imgUrl= '/prod-api'


export function getNews(data){
  return  axios.get('/tables/news/list',data);
}

export function getHomeList(data){
  return  axios.get('/tables/home/list',data);
}
export function getMessage(id) {
  return axios.get(`/tables/message/${id}`);
}
export function createMessage(data){
  return axios.post('/tables/message/create',data)
}
export function getContractus(){
  return axios.get('/tables/contractus/list');
}
export function getSiteList(){
  return axios.get('/tables/servicesitelist/list');
}
export function getJobList(){
  return axios.get('/tables/job/list');
}
export function getHonor(){
  return axios.get('/tables/honor/list');
}
export function getDevelop(type,itype){
  return axios.get('/tables/develop/list',{params: {
      type: type,
      itype: itype
    }})
}
export function getCprofile(){
  return axios.get('/tables/cprofile/list');
}
export function getCodeImg(){
  return axios.get('/captchaImage');
}
export function getProducts(ptype) {
  return axios.get('/tables/ecgnet/list', {
    params: {
      ptype: ptype
    }
  });
}
export function getService(){
  return axios.get('/table/service/list');
}
