const state = {
  locale: localStorage.getItem('locale') || 'CHN',
}

const mutations = {
  TOGGLE_LOCALE: (state) => {
    state.locale = state.locale === 'CHN' ? 'ENG' : 'CHN'
    localStorage.setItem('locale',state.locale)
  }
}

const actions = {
  toggleLocale({ commit }) {
    commit('TOGGLE_LOCALE')
  }
}
const apiUrl="http://192.168.0.159:8080/";
export default {
  namespaced: true,
  state,
  apiUrl,
  mutations,
  actions
}
