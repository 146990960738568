<template>
  <div class="contact-wrapper">
    <div class="contact-info">
      <div class="contact-title px-2">
        {{ $t('serviceMaterials.contactUs') }}
      </div>
      <!-- 总部 -->
      <div class="contact pl-2 col-auto" >
        <div class="contact-info-item">
          <div class="contact-info-icon">
            <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
          </div>
          <div class="contact-info-info" >
            {{ infoList[0].companyaddress }}
          </div>
        </div>
        <!-- 生产基地 -->
        <div class="contact-info-item">
          <div class="contact-info-icon">
            <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
          </div>
          <div class="contact-info-info">
            {{ infoList[0].productaddress}}
          </div>
        </div>
        <!-- 客户服务热线 -->
        <div class="contact-info-item">
          <div class="contact-info-icon">
            <i class="zi zi_tty"></i>
          </div>
          <div class="contact-info-info">
            {{ infoList[0].phone }}
          </div>
        </div>
        <!-- 传真 -->
        <div class="contact-info-item">
          <div class="contact-info-icon">
            <b-icon-envelope-fill></b-icon-envelope-fill>
          </div>
          <div class="contact-info-info">{{ infoList[0].fax }}</div>
        </div>

        <!-- 销售 -->
        <div class="row no-gutters col-xs-12">
          <!-- 国内销售 -->
          <div v-show="locale==='CHN'" class="col-md-6 col-xs-12" >
            <div class="contact-info-title">
              {{ $t('serviceMaterials.localSale') }}
            </div>
            <!-- 销售电话 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <i class="zi zi_tty"></i>
              </div>
              <div class="contact-info-info">
                {{ infoList[0].sealphone }}
              </div>
            </div>
            <!-- 邮箱 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <b-icon-envelope-fill></b-icon-envelope-fill>
              </div>
              <div class="contact-info-info">
                {{ infoList[0].email }}
              </div>
            </div>
          </div>

          <!-- 国际销售 -->
          <div v-show="locale==='ENG'" class="col-md-6 col-xs-12">
            <div class="contact-info-title">
              {{ $t('serviceMaterials.forignSale') }}
            </div>
            <!-- 销售电话 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <i class="zi zi_tty"></i>
              </div>
              <div class="contact-info-info">
                {{ infoList[0].sealphone }}
              </div>
            </div>
            <!-- 邮箱 -->
            <div class="contact-info-item">
              <div class="contact-info-icon">
                <b-icon-envelope-fill></b-icon-envelope-fill>
              </div>
              <div class="contact-info-info">
                {{ infoList[0].email }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Map/>
    </div>

  </div>
</template>

<script>
import { mapState} from 'vuex'
import Map from './map.vue'
import {getContractus} from "@/api/apis";

export default {
  components:{Map},
  name: 'Contact',
  data() {
    return{
      info:[],
      statusCode:0,
      infoList:[
        {
          companyaddress:undefined,
          sealphone:undefined,
          phone:undefined,
          productaddress:undefined,
          fax:undefined,
          email:undefined,
        }
      ]
    }
  },
  methods: {
    getECData(){
      getContractus().then(result=>{
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.info=result.data.rows;
        this.infoList.pop();
        for (let i=0;i<this.info.length;i++){
          if (this.info[i].itype===this.statusCode){
            this.infoList.push(this.info[i]);
          }
        }
      })
    },
  },
  mounted() {
    this.getECData()
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale
    }),
  },
}
</script>

<style lang="scss" scoped>
.contact-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
@media (min-width:720px) {
  .contact{
    margin-top: 10%;
  }
}
.contact-title {
  margin-top: 2rem;
  font-size: 1.5rem;
}
.contact-info-info{
  font-size: 0.9rem;
}
.contact-wrapper {
  .contact-info {
    width:100%;
    margin:0 auto;
    word-break: break-word;
    //font-size: 0.9rem;
    &-item {
      display: flex;
      align-items: center;
      flex: 1;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &-icon {
      // 自动填充剩余空间
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 10px;
    }
    &-info {
      flex-grow: 0;
      overflow: hidden;
      overflow-wrap: break-word;
      word-break: break-word;
      //font-size: 0.9rem;
    }
    &-title {
      margin-top: 2rem;
      font-size: 1.2rem;
    }
  }
}
</style>
