<template>
  <div class="contactus">
    <b-carousel>
      <b-carousel-slide src="/image/banner/联系.jpg">
        <div class="contact-wrapper">{{ $t('serviceInfo.contactTel') }}</div>
      </b-carousel-slide>
    </b-carousel>
    <div class="container-fulid">
      <div class="row justify-content-center no-gutters " >
        <!-- 联系方式 -->
        <div class="col-xs-12 col-auto" >
          <contact></contact>
        </div>
        <div class="col-auto hidden-xs">
          <Site />
        </div>
        <div class="col-12">
          <send-msg />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from './contact.vue'
import Site from './site.vue'
import SendMsg  from "./sendMsg.vue";
// import messageDetail  from "./messageDetail.vue";
export default {
  components: { Site, Contact,SendMsg},
  name: 'About',
  data() {
    return {
      timer:true
    }
  },
  methods:{
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    }
  },


}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.contactus{
  max-width: 90vw;
  overflow: hidden;
  margin: 0 auto;
  touch-action: none;
  touch-action: pan-y;
}
.contractus{
  margin-top: 20px;
  margin-bottom: 20px;
}
::v-deep {
  .carousel-caption {
    top: 50%;
    padding-top: 0;
    padding-bottom: 0;
    margin:0 auto;
    // background-color: rgb(228, 138, 29);
  }
}

.contact-wrapper {
  color: $sysTheameColor;
  font-size: 14px;
  text-align: left;
  font-weight: 700;
}

</style>
