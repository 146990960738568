<template>
  <div class="about-fourbtm">
    <div class="container">
    <div class="first-row">
      <div class="time-title">
        <h3 v-if="this.$store.state.app.locale === 'CHN'">至今</h3>
        <h3 v-if="this.$store.state.app.locale === 'ENG'">NOW</h3>
      </div>
      <div class="left-side">
        <div class="about-fourbtmview">
          <div class="about-fourdes fontHL" v-for="(item, index) in data2" :key="index">
            <p>{{item.title}}</p>
            <p class="word-wrap">{{item.description}}</p>
            <div class="about-fourimg" v-if="item.imgpath!==null&&item.imgpath!==''">
              <div class="about-fourimgv-left">
                <img :src="api+item.imgpath" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right-side">
        <div class="about-fourbtmview">
          <div class="about-fourdes fontHL" v-for="(item, index) in data5" :key="index">
            <p>{{item.title}}</p>
            <p class="word-wrap">{{item.description}}</p>
            <div class="about-fourimg" v-if="item.imgpath!==null&&item.imgpath!==''">
              <div class="about-fourimgv">
                <img :src="api+item.imgpath" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="second-row">
      <div class="time-title">
        <h3 class="year">2016</h3>
      </div>
      <div class="left-side">
        <div class="about-fourbtmview">
          <div class="about-fourdes fontHL" v-for="(item, index) in data1" :key="index">
            <p>{{item.title}}</p>
            <p class="word-wrap">{{item.description}}</p>
            <div class="about-fourimg" v-if="item.imgpath!==null&&item.imgpath!==''">
              <div class="about-fourimgv-left">
                <img :src="api+item.imgpath" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right-side">
        <div class="about-fourbtmview">
          <div class="about-fourdes fontHL" v-for="(item, index) in data4" :key="index">
            <p>{{item.title}}</p>
            <p class="word-wrap">{{item.description}}</p>
            <div class="about-fourimg" v-if="item.imgpath!==null&&item.imgpath!==''">
              <div class="about-fourimgv">
                <img :src="api+item.imgpath" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="third-row">
      <div class="time-title">
        <h3 class="year">2008</h3>
      </div>
      <div class="left-side">
        <div class="about-fourbtmview">
          <div class="about-fourdes fontHL" v-for="(item, index) in data0" :key="index">
            <p>{{item.title}}</p>
            <p class="word-wrap">{{item.description}}</p>
            <div class="about-fourimg" v-if="item.imgpath!==null&&item.imgpath!==''">
              <div class="about-fourimgv-left">
                <img :src="api+item.imgpath" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="right-side">
        <div class="about-fourbtmview">
          <div class="about-fourdes fontHL" v-for="(item, index) in data3" :key="index">
            <p>{{item.title}}</p>
            <p class="word-wrap">{{item.description}}</p>
            <div class="about-fourimg" v-if="item.imgpath!==null&&item.imgpath!==''">
              <div class="about-fourimgv">
                <img :src="api+item.imgpath" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { WOW } from 'wowjs'
import { imgUrl } from '@/api/apis'

export default {
  name: 'Timeline',
  data() {
    return {
      api: imgUrl,
      data0: [],
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
    }
  },
  props: {
    // data: Array
    companyData: Array,
    productionData: Array,
  },
  methods: {
    getData() {
      if (this.companyData != null ||this.productionData != null) {
        this.data0=[];
        this.data1=[];
        this.data2=[];
        for (let i = 0; i < this.companyData.length; i++) {
          if (this.isWithinRange(this.companyData[i].dtime.split("-")[0], 2008, 2010)) {
            this.data0.push(this.companyData[i]);
          } else if (this.isWithinRange(this.companyData[i].dtime.split("-")[0], 2010, 2016)) {
            this.data1.push(this.companyData[i]);
          } else if (this.isWithinRange(this.companyData[i].dtime.split("-")[0], 2017, new Date().getFullYear())) {
            this.data2.push(this.companyData[i]);
          }
        }
        for (let j=0;j<this.productionData.length;j++){
          if (this.isWithinRange(this.productionData[j].dtime.split("-")[0], 2008, 2010)) {
            this.data3.push(this.productionData[j]);
          } else if (this.isWithinRange(this.productionData[j].dtime.split("-")[0], 2010, 2016)) {
            this.data4.push(this.productionData[j]);
          } else if (this.isWithinRange(this.productionData[j].dtime.split("-")[0], 2017, new Date().getFullYear())) {
            this.data5.push(this.productionData[j]);
          }
        }
      }
    },
    isWithinRange(year, startYear, endYear) {
      const parsedYear = parseInt(year);
      return parsedYear >= startYear && parsedYear <= endYear;
    },
  },
  mounted() {
    new WOW().init()
  },
  watch: {
    companyData: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null && newValue !== '' && newValue !== oldValue) {
          this.getData();
        }
      },
    },
    productionData: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue != null && newValue !== '' && newValue !== oldValue) {
          this.getData();
        }
      },
    },
  },
}
</script>


<style lang="scss" scoped>
@import '@/styles/variables.scss';
.about-fourbtm {
  width: 100%;
  background: #fff;
  padding: 30px 0 40px;
}
.container {
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.first-row,
.second-row,
.third-row {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.float-right {
  right: 0;
}
.about-fourbtmul {
  position: relative;
  width: 100%;
  padding-left: 7px;
  padding-bottom: 1px;
}
.about-fourdes {
  margin-top: 10px;
  line-height: 22px;
  font-size: 14px;
  color: #696969;
}

.word-wrap {
  word-break:break-all;
}
.time-title{
  text-align: center;
  //margin-top: -30px;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.about-fourbtmview {
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
*, :after, :before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-repeat: no-repeat;
}
.about-fourimg {
  display: inline-block;
  width: 100%;
  margin-top: 12px;
  max-width: 360px;
}
.about-fourimgv {
  width: 100%;
  position: relative;
  height: auto;
}
.about-fourimgv-left {
  width: 100%;
  height: auto;
  position: relative;
}
.about-fourimg .about-fourimgv img {
  position: relative;
  top: 0;
  left: 0;
  width: 200px;
  height: auto;
}
.about-fourimg .about-fourimgv-left img {
  position: relative;
  top: 0;
  right: 0;
  width: 240px;
  height: auto;
}
img {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  height: auto;
}
.right-side {
  text-align: left;
  width: 50%;
  float: right;
  border-left: 1px solid #ccc;
}
.left-side {
  text-align: right;
  width:50%;
  float: left;
  padding-right: 20px;
}
@media screen and (max-width: 600px) {
  p{
    font-size: 18px;
  }
  .about-fourimgv-left {
    width: 100%;
    position: relative;
    height: 0;
    padding-top: 55.55556%;
    //overflow: hidden;
  }
  .about-fourimg .about-fourimgv-left img {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
  }
}
</style>

