const jobMaterials = {
  jobRD:'R&D Center',
  jobMarket:'Marketing Department',
  jobSale:'Sales department',
  jobListTitlePosition:'Job title',
  jobListTitleNumbers:'Number of recruits',
  jobListTitleWorkplace:'Work place',
  jobListTitleReleaseDate:'Release time',
  jobDuty:'Job responsibilities',
  jobRequires:'Qualifications',
  jobPostResume:'Resume delivery',
  jobReceiveMail:'info@3ray.com.cn',
  jobNoJobs:'No message',
}
module.exports = {
  jobMaterials
}
