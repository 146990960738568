<style lang="scss" scoped>
.news-wrapper {
  margin-top: 10px;
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }
  .news-img {
    // background-color: cadetblue;
    overflow: hidden;
    height: auto;
    width: auto;
    display: flex;
    display: -webkit-flex;
    //justify-content: end;
    margin-bottom: 20px;
    img {
      flex-shrink: 0;
      flex-grow: 0;
      // max-height: 150px;
    }
    //img:hover {
    //  transform: scale(1.2);
    //}
  }

  .news-main:hover{
    background-color: whitesmoke;
  }

  .news-title {
    color: rgb(0, 0, 0);
    font-size: 1rem;
  }
  .news-detail {
    color: #444;
    margin-top: 10px;
    text-indent: 2rem;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 2.5rem;
    display:-webkit-box;
  }
}
.news-wrapper::after {
  display: block;
  content: '';
  height: 1px;
  width: 100%;
  border-bottom: 1px dashed #555;
  margin-top: 10px;
}
.news-date{
  margin-top: 10px;
  color: #666;
  font-size: 0.9rem;
}
</style>
<template>
  <div class="news-wrapper">
    <a :href="newsItem.newspath" target="_blank">
      <div class="container-fulid d-flex justify-content-center" >
        <div class="row news-main align-items-center" v-show="locale === 'CHN'">
          <div class="news-img col-12 col-md-3">
            <b-img-lazy  fluid  class="mx-auto"  :src="api+newsItem.imgpath" center ></b-img-lazy>
          </div>

          <div class="news-content col-12 col-md-9">
            <div class="news-title">
              {{ newsItem.title }}
            </div>
            <div class="news-detail">{{ newsItem.content }}</div>
            <div class="news-date">
              <b-icon-calendar></b-icon-calendar>
              <span class="ml-2" >{{ newsItem.ptime }}</span>
            </div>
          </div>
        </div>
      </div>
    </a>
    <div v-show="locale === 'ENG'">
      <div class="news-content col-12 col-md-10">
        <div class="news-title">
          {{ newsItem.title }}
        </div>
        <div class="news-detail">{{ newsItem.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {imgUrl} from'@/api/apis'
export default {
  data() {
    return {
      showmaker:true,
      api:imgUrl,
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
      service: (state) => state.service.serviceInfo,
      serviceValid: (state) => state.service.valid
    })
  },
  methods:{

  },
  props: {
    newsItem: {
      type: Object
    }
  },
}
</script>

