<template>
  <div>
    <div ref="mapContainer" class="map"></div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadMapScript();
  },
  methods: {
    loadMapScript() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://map.qq.com/api/js?v=2.exp&key=6YLBZ-WTDWU-X3DVW-GCHJS-KYFIZ-HAFTN";

      script.onload = this.initMap;
      script.onerror = () => {
        console.error("Error loading map script.");
      };

      document.head.appendChild(script);
    },

    initMap() {
      // eslint-disable-next-line no-undef
      if (typeof qq !== "undefined" && typeof qq.maps !== "undefined") {
        // eslint-disable-next-line no-undef
        var myLatlng = new qq.maps.LatLng(23.210751, 113.362352);
        var myOptions = {
          zoom: 18,
          center: myLatlng,
          // eslint-disable-next-line no-undef
          mapTypeId: qq.maps.MapTypeId.ROADMAP
        };
        // eslint-disable-next-line no-undef
        this.map = new qq.maps.Map(this.$refs.mapContainer, myOptions);
        // 创建标注
        //eslint-disable-next-line no-undef,no-unused-vars
        var marker = new qq.maps.Marker({
          position: myLatlng,
          // eslint-disable-next-line no-undef
          animation:qq.maps.MarkerAnimation.DOWN,
          map: this.map,
          // addr:"广州三锐电子科技有限公司",
        });
        // eslint-disable-next-line no-undef
        var infoWin = new qq.maps.InfoWindow({
          map: this.map
        });
        infoWin.open();

        infoWin.setContent('广州三锐电子科技有限公司');
        infoWin.setPosition(myLatlng);

        // marker.setIcon(icon);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.map{
  width: 480px;
  height: 400px;
}
@media screen and (max-width: 500px){
  .map{
    width: 90vw;
  }
}
</style>
