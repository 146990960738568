<style scoped lang="scss">
.contact-form {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 100vh;
  max-width: 90vw;
  // background-color: #f8f9fa;  // 可以选择合适的背景颜色
}
.login-code-img {
  cursor: pointer;
  width: 30%; /* 30% 的宽度 */
  margin-left: 5px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  width: 240px;
  height: 120px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.input-container {
  display: flex;
  align-items: center;
}
.input-field {
  flex: 7; /* 70% 的宽度 */
}
.error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.error-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.error-modal-content p {
  margin-bottom: 10px;
}
.error-modal-content button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.error-modal-content button:hover {
  background-color: #0056b3;
}
.form {
  padding: 40px;
  border-radius: 10px;
  //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 1600px;
  width: 90%;
  background-color: #fff;
}
.captcha-container {
  display: flex;
  align-items: center;
}
.captcha-container input {
  width: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.captcha-container .captcha-btn {
  width: 200px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px;
}
.captcha-container .captcha-btn:hover {
  background-color: red;
}
.form-header {
  text-align: center;
  margin-bottom: 20px;
}

.form-row {
  margin-bottom: 20px;
}

.form-row label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.form-row input,
.form-row textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-row textarea {
  resize: vertical;
}

.form-row-inline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.form-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.clear-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clear-btn:hover {
  background-color: #b02a37;
}
</style>

<template>
  <div class="contact-form">
    <form  class="form col-12 col-sm-12">
      <div class="form-header">
        <h1>{{title}}</h1>
      </div>
      <div class="form-row row">
        <div class="col-md-6">
          <label for="name">{{name}}</label>
          <input type="text" id="name" v-model="formData.name" required>
        </div>
        <div class="col-md-6">
          <label for="phone">{{phone}}</label>
          <input type="text" id="phone" v-model="formData.phone" required>
        </div>
      </div>
      <div class="form-row">
        <label for="suggestion">{{ suggestion }}</label>
        <textarea id="suggestion" v-model="formData.suggestion" required></textarea>
      </div>
      <div class="form-actions">
        <button class="submit-btn" @click="submitForm">{{button0}}</button>
        <button type="button" class="clear-btn" @click="clearForm">{{button1}}</button>
      </div>
    </form>
  </div>
</template>
<script>
import {createMessage, getCodeImg} from "@/api/apis"
export default {
  data() {
    return {
      codeUrl: "",
      countdown: 60,
      canGetCode: true,
      showErrorModal: false,
      title:"",
      name:"",
      phone: "",
      suggestion:"",
      code:"",
      codeImg:"",
      button0:"",
      button1:"",
      showSuccessModal:false,
      formData: {
        name: '',
        email: '',
        phone: '',
        suggestion: '',
        company:'',
        captcha: "",
        code: "",
      },
    };
  },
  methods: {
    showInfo(){
      if (this.$store.state.app.locale==="CHN"){
        this.title="留言信息";
        this.name="姓名";
        this.phone="手机";
        this.suggestion="留言";
        this.button0="提交";
        this.button1="清空";
        this.code="验证码";
      }else if (this.$store.state.app.locale==="ENG"){
        this.title="Message Information";
        this.name="Name";
        this.phone="PhoneNumber";
        this.suggestion="Message";
        this.button0="Submit";
        this.button1="Clear";
        this.code="Verification Code";
      }
    },
    getCode() {
      getCodeImg().then(response => {
        console.log(response.data);
        this.codeUrl = "data:image/gif;base64," + response.data.img;
        this.codeImg=response.data.recode;
        console.log(this.codeImg)
        this.$message('提交成功');
      })
        .catch(error => {
          this.$message('提交失败请重新提交');
          console.error('Error fetching captcha:', error);
        });
    },
    validateCaptcha(inputCaptcha) {
      return inputCaptcha === this.codeImg;
    },

    startCountdown() {
      this.countdown = 300; // 设置倒计时初始值为300秒
      this.canGetCode = false; // 禁用获取验证码按钮
      const intervalId = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(intervalId); // 清除定时器
          this.canGetCode = true; // 启用获取验证码按钮
        }
      }, 1000);
    },
    closeErrorModal() {
      this.showErrorModal = false;
    },
    closeSuccessModal() {
      this.showSuccessModal=false;
    },
    submitForm() {
      // if (this.validateCaptcha(this.formData.code)) {
        const requestData = {
          phonenumber: this.formData.phone,
          name: this.formData.name,
          message: this.formData.suggestion
        };
        createMessage(requestData).then((res)=>{
          console.log(res);
          if (res.data.code === 200){
            this.clearForm();
            this.$message({
              message: '提交成功',
              type: 'success'
            });
          } else {
            this.$message.error('出了点小问题，请稍后再试');
            this.showErrorModal = true;
          }
        }).catch((err)=>{
          console.log(err);
        })
      // } else {
      //   this.showErrorModal = true;
      // }
    },
    clearForm() {
      this.formData = {
        name: '',
        phone: '',
        suggestion: ''
      };
    },
  },
  mounted() {
    this.showInfo()
    // this.getCode()
  }
};
</script>
