import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//全局css
import '@/styles/sys.scss'

// 动画库用到css
import 'animate.css'
import { Timeline, Card, TimelineItem, Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
Vue.use(Timeline)
Vue.prototype.$message = Message
Vue.use(TimelineItem)
Vue.use(Card)
//============== bootstrap-vue ================================
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'default-passive-events'

// =============================================================
// echarts
import echarts from './utils/echartsUi'
Vue.prototype.$echarts = echarts

// 百度地图
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, { ak: 'rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR' })

axios.defaults.baseURL = '/prod-api'

Vue.config.productionTip = false

// 国际化
import i18n from './utils/i18n'

new Vue({
  router,
  i18n,
  store,
  el: '#app',
  render: (h) => h(App)
}).$mount('#app')
