<template>
  <div>
    <div class="card-wrapper">
      <!-- :src="product.img" -->
      <a :href="product.path" @click.prevent="handleProductDetail">
        <div class="card-img">
          <b-img
            class="pic"
            fluid-grow
            :src="api + product.imgpath"
            center
            thumbnail
            :alt="product.name"
            :style="getDefaultImageStyles()"
          >
          </b-img>
        </div>
        <div class="card-name">{{ product.name }}</div>
      </a>
    </div>
  </div>
</template>

<script>
import { imgUrl } from '@/api/apis'

export default {
  name: 'ProductCard',
  data() {
    return {
      api: imgUrl,
      imgScale: 1,
      isHovered: false, // 添加 isHovered 状态
    }
  },
  props: {
    product: Object,
  },
  methods: {
    handleProductDetail() {
      this.$emit('showDetail', this.product)
    },
    getDefaultImageStyles() {
      return {
        transform: `scale(${this.imgScale})`,
      };
    },
    getImageStyles() {
      const scale = this.isHovered ? 1.1 : 1;
      return {
        transform: `scale(${this.imgScale * scale})`,
      };
    },
    async loadImage() {
      const img = new Image();
      img.src = this.api + this.product.imgpath;

      await new Promise((resolve) => {
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          console.log(width, height);
          if (height > width) {
            this.imgScale = 0.5;
          } else {
            this.imgScale = 0.75;
          }
          resolve();
        };
      });
    },
  },
  mounted() {
    this.loadImage();
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.card-wrapper {
  height: auto;
  min-width: 250px;
  max-width: 300px;
  margin: 10px 50px;
  overflow: hidden;
  cursor: pointer;

  .card-img {
    height: 250px;
    margin: auto;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid rgb(230, 219, 219);
    overflow: hidden;
    display: flex;
    align-items: center;
    -webkit-touch-callout: none;
    pointer-events: none;

    .img-thumbnail {
      border: none;
    }
  }

  .card-name {
    margin-top: 1rem;
    height: 60px;
    line-height: 30px;
    text-align: center;
    color: #444;
    font-size: 1rem;
    word-break: break-word;
  }

  a {
    text-decoration: none;
    color: rgb(43, 43, 43);
  }

  .card-wrapper:hover {
    transform: translate(0, -10px);
    transition: all 0.4s;

    .pic {
      transform: scale(1.5);
      transition: all 0.5s;
    }
  }
}

@media screen and (min-width: 576px) {
  .card-wrapper {
    margin: 10px 10px;
  }
}

@media screen and (min-width: 992px) {
  .card-wrapper {
    margin: 10px 10px;
  }
}
</style>
