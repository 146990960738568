<template>
  <div>
    <div class="card-wrapper">
      <product-card
        v-for="(item, index) in products"
        class="card-item"
        :key="index"
        :product="item"
        @showDetail="handleProductDetail"
      ></product-card>
    </div>

    <b-modal centered size="xl" v-model="showDetail">
      <template v-slot:modal-header>
        <div class="product-module-header" style="word-wrap: break-word">
          {{ currentShowProduct.name }}
        </div>
      </template>
      <product :product="currentShowProduct"></product>
      <template v-slot:modal-footer="{ ok }">
        <div class="product-module-footer">
          <b-button block variant="success" @click="ok">
            {{ $t('serviceMaterials.butt') }}</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Product from './product.vue'
import ProductCard from './productCard.vue'
export default {
  name: 'ProductList',
  data() {
    return {
      currentShowProduct: {},
      showDetail: false
    }
  },
  props: {
    products: {
      type: Array,
      required: true,
      message: '请至少选择一个',
      trigger: 'change'
    }
  },
  methods: {
    handleProductDetail(product) {
      this.currentShowProduct = product
      this.showDetail = true
    }
  },

  components: { ProductCard, Product }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.card-wrapper {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  margin-top:20px;
  .card-item {
    flex: 0 1 33.3%;
    display: flex;
  }
}

::v-deep {
  .modal {
    padding-left: 0 !important;
  }
  .modal-dialog {
    margin: auto auto;
  }
  .modal-header {
    padding: 0;
  }
  .modal-footer {
    justify-content: center;
  }
}

.product-module-header {
  height: 70px;
  width: 100%;
  background-color: $sysTheameColor;
  text-align: center;
  font-size: 1.7rem;
  line-height: 70px;
  margin-left: 0;
  margin-right: 0;
  color: white;
}

@media screen and (max-width: 576px) {
  .product-module-header {
    font-size: 1.2rem;
    height: 50px;
    line-height: 50px;
  }
  .card-wrapper{
    justify-content: center;
  }
}

.product-module-footer {
  width: 100%;
  .btn-success {
    background-color: $sysTheameColor;
    border-color: $sysTheameColor;
  }
}
</style>
