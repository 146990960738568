
const homeMaterials = {
  homePopularProduct:'热销产品',
  homeAbout:'关于我们',
  homeAboutImg: require('/public/image/home/about.jpg'),
  homeAboutTitle:'设计安全产品，提供优质服务',
  homeAboutDetail:`三锐公司作为专业心电图解决方案供应商，我们坚持以【技术、品质、服务】的经营原则，
                  以诚信、服务、创新的合作理念，诚邀全国各地经销商及代理商，共创互惠、发展的美好未来。`,
  homeNews:'最新动态',
  homeMore:'更多+',
  photo1:require('/public/image/home/轮播图/01.jpg'),
  photo2:require('/public/image/home/轮播图/02.jpg'),
  photo3:require('/public/image/home/轮播图/03.jpg')

}

module.exports = {
  homeMaterials,
}
