<style lang="scss" scoped>

</style>

<template>
  <div class="ecg">
        <product-list  :products="ecgProducts"></product-list>
  </div>
</template>

<script>
import ProductList from './components/productList.vue'
import {mapState} from 'vuex'
import {getProducts} from "@/api/apis";

export default {
  name: 'Ecg',
  components: { ProductList },
  data(){
    return{
      ecgProducts:[],
      statusCode:0,
      ecgProductsList:[],
      timer:true
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale,
    })
  },
  methods: {
    getECData(){
      getProducts(0).then(result=>{
        // this.jobsList=result.data.rows;
        if (this.$store.state.app.locale==="CHN"){
          this.statusCode=0;
        }else if (this.$store.state.app.locale==="ENG"){
          this.statusCode=1;
        }
        this.ecgProductsList=result.data.rows;
        this.ecgProducts.pop();
        for (let i=0;i<this.ecgProductsList.length;i++){
          if (this.ecgProductsList[i].itype===this.statusCode){
            this.ecgProducts.push(this.ecgProductsList[i]);
          }
        }
      })
    },
    sleep(d) {
      return new Promise((resolve) => setTimeout(resolve, d))
    },
    async test() {
      this.timer = true;
      console.log('Just waiting,Crazy workday, v me 50')
      await this.sleep(200)
      this.timer=false;
    }

  },
  mounted() {
    this.getECData()
    this.test()
  }
}
</script>
