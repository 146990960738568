<template>
  <div id="main-top-nav" class="nav-header container-fluid">
    <div class="row">
      <!--空白列，平板，手机不显示 -->
      <div class="d-sm-none d-md-block col-xl-2 col-lg-1 col-md-1"></div>
      <!-- logo 列 -->
      <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-6">
        <div class="logo-wrapper">
          <a v-show="locale=='CHN'" href="/">
            <img :src="logoUrl" class="img-logo" alt="logo" />
          </a>
          <a v-show="locale=='ENG'" href="/">
            <img :src="logoUrl1" class="img-logo" alt="logo" />
          </a>
        </div>
      </div>

      <!-- 菜单列 -->
      <div class="col-xl-6 col-lg-8 col-md-8 col-sm-6 col-1">
        <div
          class="
            d-md-flex d-none d-md-block
            flex-row
            justify-content-around
            align-items-start
            menu-item-wrapper
          "
        >
          <div
            class="menu-item"
            v-for="item in $t('mainNavList')"
            :key="item.name"
          >
            <router-link :to="item.path" >
              <span :class="{ 'current-item': isCurrentItem(item) }" >{{item.name }}</span>
              <div
                v-if="item.children.length > 0"
                class="menu-item-child-content"
              >
                <router-link
                  class="menu-item-child"
                  v-for="child in item.children"
                  :key="child.name"
                  :to="child.path"
                >
                  {{ child.name }}
                </router-link>
              </div>
            </router-link>
            <i class="menu-item-underline" v-if="item.children.length > 0"></i>
          </div>
        </div>
      </div>

      <!-- 语言切换，手机菜单按钮-->
      <div
        class="
          col-xl-2 col-lg-1 col-md-1 col-sm-3 col-5
          d-flex
          flex-row
          justify-content-around
          align-items-center
        "
      >
        <!-- 语言选择 -->
        <b-select
          size="sm"
          v-model="localeText"
          :options="slectOptions"
          @change="handleLanguageChange"
        ></b-select>
        <!-- 列表按钮 手机，平板下可见-->
        <b-button
          class="menu-toggle-btn d-sm-block d-md-none align-bottom"
          id="menu-toggle-btn"
          v-b-toggle.collapse-main
          variant="light"
          size="sm"
        >
          <b-icon icon="list" variant="dark"></b-icon>
        </b-button>
      </div>
    </div>
    <!-- 移动端的导航栏，通过按钮激活 -->
    <b-collapse id="collapse-main" class="menu-collapse-contain" style="flex-wrap: wrap">
      <div v-for="(item, index) in $t('mainNavList')" :key="index">
        <div class="mobile-nav-wrapper">
          <div class="mobile-nav-item" @click="handleItemClick(item)">
            {{ item.name }}
          </div>
          <b-button
            variant="light"
            v-if="item.children.length > 0"
            v-b-toggle="getCollspseIdName('submenu', index)"
          >
            <b-icon-chevron-right></b-icon-chevron-right>
          </b-button>
        </div>
        <b-collapse
          :id="getCollspseIdName('submenu', index)"
          accordion="my-accordion"
          v-if="item.children.length > 0"
        >
          <div
            class="mobile-nav-sub-item"
            v-for="(subItem, index) in item.children"
            :key="index"
            @click="handleItemClick(subItem)"
          >
            {{ subItem.name }}
          </div>
        </b-collapse>
      </div>
    </b-collapse>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'NavBar',
  data() {
    return {
      active:'',
      logoUrl: require('/public/image/logo/logo.png'),
      logoUrl1: require('/public/image/logo/logo1.png'),
      menuVisible: false,
      localeText: this.locale,
      slectOptions: [
        { value: 'CHN', text: 'CHN' },
        { value: 'ENG', text: 'ENG' }
      ]
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.app.locale
    })
  },
  methods: {
    isCurrentItem(item) {
      // console.log(this.$route.path, item.path)
      return this.$route.path.search(item.path) >= 0
    },
    getCollspseIdName(item, index) {
      return 'collapse-' + item + index
    },

    handleItemClick(item) {
      this.$root.$emit('bv::toggle::collapse', 'collapse-main')
      this.$router.push(item.path)
    },
    handleLanguageChange() {
      this.$store.dispatch('job/setJobInValid')
      this.$store.dispatch('news/setNewsInValid')
      this.$store.dispatch('products/setProductsInvalid')
      this.$store.dispatch('certicicate/setCerticicateInValid')
      this.$store.dispatch('app/toggleLocale')
      this.$i18n.locale = this.locale
      this.$nextTick(() => {
        console.log(this.$i18n.locale)
        this.$router.go(0)
      })
    }
  },
  mounted() {
    this.localeText = this.locale;


    // eslint-disable-next-line no-unused-vars
    this.$router.afterEach((to, from) => {
      this.$nextTick(() => {
        this.$root.$emit('updateNavItemActive', '');
        this.$root.$emit('updateNavItemActive', to.path);
        localStorage.setItem('activePath', to.path);
      });
    });

    const activePath = localStorage.getItem('activePath');
    if (activePath) {
      this.$root.$emit('updateNavItemActive', activePath);
    } else {
      this.$root.$emit('updateNavItemActive', '');
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.nav-header {
  position: fixed;
  top: 0;
  height: $sysHeaderHeight;
  background: rgba(255, 255, 255, 0.95);
  border-bottom: 1px solid rgba(175, 168, 168, 0.2);
  z-index: 30;
}
.logo-wrapper {
  height: $sysHeaderHeight;
  .img-logo {
    height: 50px;
    text-align: center;
    margin-top: 11px;
  }
}

.menu-item-wrapper {
  font-size: 1.2rem;
  height: $sysHeaderHeight;

  // 取消下划线
  a {
    text-decoration: none;
    color: $defaultTextColor;
  }

  .menu-item {
    min-height: calc(#{$sysHeaderHeight} - 2px);
    // width: 100px;
    line-height: 70px;
    text-align: center;
    cursor: pointer;
    // border-bottom: 2px solid palevioletred;

    a {
      text-decoration: none;
      color: $defaultTextColor;
    }
    a:hover{
      text-decoration: none;
      color: $sysTheameColor;
    }
    .menu-item-child-content {
      font-size: 1rem;
      display: none;
      position: absolute;
      margin-top: 2px;
      background-color: #fff;
      background: rgba(255, 255, 255, 0.8);
      min-width: 100px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      text-align: center;

      .menu-item-child {
        display: block;
        height: 40px;
        line-height: 40px;
        &:hover {
          background-color: rgb(200, 200, 200);
        }
      }
    }
  }

  .current-item {
    color: $sysTheameColor;
  }
  .menu-item:hover .menu-item-child-content {
    display: block;
  }
  .menu-item:hover {
    .menu-item-underline {
      margin: auto;
      display: block;
      height: 1px;
      border: 1px solid $sysTheameColor;
      -webkit-animation: myfirst 1s;
      animation: myfirst 1s;
      align-items: center;
    }
  }
}

.menu-toggle-btn {
  &:focus {
    box-shadow: none;
    background-color: white;
    // border:none;
  }
}
.menu-toggle-btn.collapsed {
  svg {
    transform: rotate(0deg);
  }
}
.menu-toggle-btn.not-collapsed {
  svg {
    transform: rotate(90deg);
  }
}
@keyframes myfirst {
  from {
    width: 30%;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes myfirst /* Safari and Chrome */ {
  from {
    width: 30%;
  }
  to {
    width: 100%;
  }
}

.custom-select {
  width: auto;
  cursor: pointer;
  background: none;
  margin: auto;
  border: none;
  padding: 5px 5px;
  &:focus{
    box-shadow: none;
  }
}

.menu-collapse-contain {
  box-sizing: content-box;
  margin-bottom: 30px;
  margin-left: -15px;
  width: 100vw;
  height: 100%;
  overflow: scroll;
  min-height: calc(100vh - #{$sysHeaderHeight});
  background-color: white;
  a {
    text-decoration: none;
    color: $sysTheameColor;
  }
  .list-group-item:last-child {
    border-bottom: none;
  }
}
.menu-item-link-wrapper {
  display: inline-table;
  width: 100%;
  .sub-menu-btn-wrapper {
    line-height: 38px;
  }
  a {
    display: inline-block;
    width: calc(100% - 50px);
    font-size: 1.2rem;
    line-height: 38px;
  }
}

.mobile-nav-wrapper {
  height: 50px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;

  .btn {
    margin: 5px 0;
    background-color: white;
    border: none;
    color: black;
  }
  .collapsed {
    svg {
      transform: rotate(0deg);
    }
  }
  .not-collapsed {
    svg {
      transform: rotate(90deg);
    }
  }
  .btn:focus {
    // border:none;
    box-shadow: none;
  }
}

.mobile-nav-item {
  font-size: 1.5rem;
  flex-grow: 1;
  height: 50px;
  line-height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  color: $sysTheameColor;
  &:hover {
    background-color: $sysTheameColor;
    color: white;
  }
}

.mobile-nav-sub-item {
  font-size: 1.3rem;
  margin: 0px 20px;
  height: 40px;
  line-height: 40px;
  color: $sysTheameColor;
  &:hover {
    background-color: $sysTheameColor;
    color: white;
  }
}
</style>
