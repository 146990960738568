<template>
  <div class="nav-wrapper">
    <div v-for="(item, index) in itemList" :key="index" class="nav-item-wrapper">
      <div
        :class="[
          'nav-item',
          { 'active-item': itemIsActive(item.path) || isMouseOver(index) }
        ]"
        @click="handleNavClick(item.path, index)"
        @mouseenter="mouseOver(index)"
        @mouseleave="mouseLeave(index)"
      >
        <div class="item-title" ref="itemTitle">
          {{ item.name }}
        </div>
        <div class="bottom-line"></div>
      </div>
      <div v-if="index < itemList.length - 1" class="nav-separator">•</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavItem',
  data() {
    return {
      activeIndex: -1, // 默认不选中任何项
      activePath: '',
      hoverIndex: -1
    };
  },
  props: {
    itemList: Array,
    defaultActivePath: String
  },
  methods: {
    mouseOver(index) {
      this.hoverIndex = index;
    },
    // eslint-disable-next-line no-unused-vars
    mouseLeave(index) {
      this.hoverIndex = -1;
    },
    handleNavClick(path, index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
        this.$emit('handleNavChange', path);
      }
    },
    itemIsActive(path) {
      return this.activePath === path ;
    },
    isMouseOver(index) {
      return this.hoverIndex === index;
    }
  },
  watch: {
    $route(to) {
      this.activePath = to.path;
      this.activeIndex = this.itemList.findIndex(item => item.path === to.path);
    }
  },
  mounted() {
    this.$root.$on('updateNavItemActive', path => {
      this.activePath = path;
      this.activeIndex = this.itemList.findIndex(item => item.path === path);
    });
  }

};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

$borderCorlor: #ddd;
$navItemHeight: 3rem;
$navItemBorderRadius: $navItemHeight * 0.5;
.nav-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.nav-item-wrapper {
  display: inline-block;
  text-align: center;
  position: relative;
}

.nav-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-bottom: 8px;
  border-bottom: $sysTheameColor;
}

.nav-item .bottom-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $sysTheameColor;
  color: $sysTheameColor;
  transform-origin: left bottom;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}
.active-item{
  color: $sysTheameColor;
}
.nav-item.active-item .bottom-line {
  transform: scaleX(1);
  background-color: $sysTheameColor;
  color: $sysTheameColor;
}

.nav-separator {
  display: inline-block;
  margin: 0 10px;
  font-size: 14px;
  color: #999;
}
.item-title {
  display: flex;
  align-items: center;
  line-height: $navItemHeight;
  font-size: 1.2rem;
  white-space: nowrap;
}
@media screen and  (min-width: 577px){
  .nav-wrapper {
    .item-title {
      font-size: 1.2rem;
    }
  }
}
@media screen and (max-width: 576px) {
  .nav-wrapper {
    .item-title {
      font-size: 1rem;
    }
  }
}
</style>
