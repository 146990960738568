<style lang="scss" scoped>
@import '@/styles/variables.scss';
.product-header {
  height: 70px;
  background-color: $sysTheameColor;
  text-align: center;
  font-size: 1.7rem;
  line-height: 70px;
  margin:0 auto;
  color: white;
}

.product-img {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  -webkit-touch-callout: none;
  pointer-events: none;
}
.product-title {
  // margin-top: 50px ;
  // margin: 20px auto;
  color: $sysTheameColor;
  text-align: center;
  font-size: 1.2rem;
}
.product-detail {
  .list-item {
    display: flex;
    display: -webkit-flex;
    line-height: 2rem;

    svg {
      margin-top: 0.5rem;
      color: $sysTheameColor;
    }
    .list-item-desc {
      flex-grow: 0;
      overflow: hidden;
      overflow-wrap: break-word;
      word-break: break-all;
      //margin-left: 10px;
      color: #555;
      font-size: 0.9rem;
    }
  }
  //带标题的详情
  .list-item-title1 {
    display: flex;
    display: -webkit-flex;
    margin-top: 20px;
    .list-item-number {
      margin-top: 0.7rem;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      vertical-align: middle;
      flex-shrink: 0;
      div {
        text-align: center;
        line-height: 30px;
        color: white;
        font-weight: 700;
      }
      background-color: $sysTheameColor;
    }
    .list-item-title1-text {
      font-size: 1.2rem;
      margin: 10px;
      color: $sysTheameColor;
    }
  }

  .list-item-title1-desc {
    color: #333;
    font-size: 0.9rem;
    margin-left: 30px;
  }
}
.group-detail {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  .group-title {
    font-size: 1.2rem;

    color: $sysTheameColor;
    border-bottom: 1px solid #53829b;
  }
  .group-desc {
    color: #333;
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
}

.product-footer {
  text-align: center;
  margin: 20px;

  a {
    text-decoration: none;
  }
}
</style>

<template>
  <div>
    <!-- 顶部标题 -->
    <!-- <div class="product-header">{{ product.name }}</div> -->
    <!-- 产品大图 -->
    <div class="product-img">
      <div class="container-fulid">
        <div class="row justify-content-center no-gutters">
          <div class="col-sm-10 col-md-8 col-lg-8 col-xl-8">
            <b-img
              fluid-grow
              :src="api+product.imgpath"
              :alt="product.name"
              center
            ></b-img>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品型号 -->
    <div class="mt-4 product-title">{{ product.title }}</div>

    <!-- 产品详细介绍 -->
    <div class="product-detail my-5 mx-sm-1">
      <b-row no-gutters >
<!--        v-for="(item, index) in product" :key="index"-->
        <!-- 详情列表 -->
<!--        v-if="item.type === 'listitem'"-->
<!--        -->
        <div v-if="product.ptype === 0||product.ptype===3||product.ptype===4||product.ptype===5||product.ptype===7">
          <div
            class="list-item d-flex"
            v-for="(desc, itemIndex) in stringCut(product.content1)"
            :key="itemIndex"
          >

            <b-icon-circle-fill
              scale="0.6"
              class="ml-sm-4"
            ></b-icon-circle-fill>
            <div class="list-item-desc" >{{desc}}</div>
          </div>
        </div>

        <!-- 带标题的列表 -->
        <div v-if="product.ptype === 2">
          <div v-for="(titleItem, titleIndex) in stringCut(product.title1)" :key="titleIndex">
            <div class="list-item-title1">
              <div class="list-item-number">
                <div>0{{ titleIndex + 1 }}</div>
              </div>
              <div class="list-item-title1-text">{{ titleItem }}</div>
            </div>
            <div
              class="list-item-title1-desc"
            >
              {{ contentList[titleIndex] }}
            </div>
          </div>
        </div>
        <!--分组的列表  -->
        <div
          class="group-detail justify-content-start justify-content-md-around"
          v-if="product.ptype === 1||product.ptype ===6"
        >
          <div v-for="(groupItem, groupIndex) in stringCut(product.title1)" :key="groupIndex">
            <div class="group-title">{{ groupItem }}</div>
            <div
              class="group-desc"
              v-for="(descItem, descIndex) in stringCut(groupIndex==0? product.content1 :product.content2)"
              :key="descIndex"
            >
              {{ descItem }}
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <!-- 同类产品 -->
    <div class="product-brother hidden-md-and-up " v-if="product.ptype===3||product.ptype===4||product.ptype===5">
<!--      <hr  />-->
      <div class="d-flex justify-content-center">
        <div
          class=" d-flex flex-column mx-5  flex-shrink-3 flex-wrap "
          v-if="product.relatedproduct1 !=null"
        >
<!--          v-for="(item, index) in stringCut(product.relatedProduct1)"-->
<!--          :key="index"-->
          <b-img fluid :src="api+product.relatedproduct1"></b-img>
          <div style="text-align: center">{{ product.title1 }}</div>

        </div>
        <div
          class=" d-flex flex-column mx-5  flex-shrink-3 flex-wrap "
          v-if="product.relatedproduct2 !=null"
        >
          <b-img fluid :src="api+product.relatedproduct2"></b-img>
          <div style="text-align: center">{{ product.title2 }}</div>
        </div>
        <div
          class=" d-flex flex-column mx-5  flex-shrink-3 flex-wrap "
          v-if="product.relatedproduct3!=null"
        >
          <b-img fluid :src="api+product.relatedproduct3"></b-img>
          <div style="text-align: center">{{ product.title3 }}</div>
        </div>
      </div>
    </div>

<!--    <div class="product-footer d-none">-->
<!--      <router-link :to="product.from"-->
<!--      ><b-icon-arrow-left></b-icon-arrow-left> 返回列表</router-link-->
<!--      >-->
<!--    </div>-->
  </div>
</template>

<script>
import {imgUrl} from '@/api/apis'
export default {
  name: 'Product',
  data() {
    return {
      contentList:[],
      str:[],
      api:imgUrl,
    }
  },
  props: {
    product: {
      type: Object
    }
  },
  methods:{
    stringCut(dec){
      if(dec){
        let list=dec.split("；");
        return list;
      }

    },
    getContentList(){
      this.contentList.pop();
      console.log(this.product.content1)
      for (let i=0;i<10;i++){
        this.str[i]=this.stringCut(this.product.content1)[i];
        this.contentList.push(this.str[i]);
      }
    }
  },
  mounted() {
    this.getContentList();
  }
}
</script>

